import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Parser from "html-react-parser"

import Section from "../elements/Section"
import Bloc from "./Bloc"
import globalVariables from "../globalVariables"
import { getBackgroundColor } from "../Home/Clients"

const Row = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const Image = styled.div`
  flex: 0 1 35%;
  position: relative;
  border-top: 1px solid white;
  ${getBackgroundColor};
  img {
    z-index: -1;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @media (max-width: ${globalVariables.maxMobile}) {
    order: ${props => (props.order ? props.order : null)};
  }
`

const WrapperLogo = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;

  img {
    z-index: 1;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    width: ${props => (props.width ? props.width : "150px")};
  }
`

const EtudeDeCasBlocs = ({
  logo,
  vignette,
  couleur,
  client,
  contexte,
  missions,
  resultats,
}) => {
  return (
    <Section padding="0">
      <Row>
        <Bloc
          borderRight
          order="1"
          flex="0 0 50%"
          bgColor="secondary"
          color="white"
          title="Le Client"
          subTitle={client.titre_section_client}
          text={Parser(client.texte_section_client)}
        />
        <Bloc
          order="2"
          right
          flex="0 0 50%"
          bgColor="primary"
          color="white"
          title="Le Contexte"
          subTitle={contexte.titre_section_contexte}
          text={Parser(contexte.texte_section_contexte)}
        />
        <Image order="5" background={couleur}>
          <Img fluid={vignette} />
          <WrapperLogo width="165px">
            <Img fluid={logo} />
          </WrapperLogo>
        </Image>
        <Bloc
          order="3"
          flex="0 0 65%"
          title="Nos Missions"
          subTitle={missions.titre_missions}
          text={Parser(missions.texte_missions)}
        />
        <Bloc
          order="4"
          container
          bgColor="primary"
          color="white"
          flex="0 0 100%"
          title="Les Résultats"
          subTitle={resultats.titre_resultats}
          text={Parser(resultats.texte_resultats)}
        />
      </Row>
    </Section>
  )
}

export default EtudeDeCasBlocs
