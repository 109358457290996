import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Parser from "html-react-parser"
import Flip from "react-reveal/Flip"

import Section from "../elements/Section"
import Container from "../elements/Container"
import globalVariables from "../globalVariables"
import WrapperDots, { Dots } from "../Home/Accompagnement/DotsCarousel"
import { NextArrow, PrevArrow } from "../elements/ArrowCarousel"

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.secondaryWithOpacity};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  margin-top: 4rem;
  align-items: center;
  text-align: center;
  @media (min-width: ${globalVariables.minTablet}) {
    justify-content: center;
  }
`
const Title = styled.h1`
  color: ${props => props.theme.white};
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  position: relative;
  /* text-transform: uppercase; */
  &::after {
    content: "";
    position: absolute;
    bottom: -26px;
    left: 50%;
    height: 4px;
    width: 280px;
    background: ${props => props.theme.primary};
    transform: translateX(-50%);
  }
  @media (min-width: ${globalVariables.minTablet}) {
    &::after {
      width: 375px;
    }
  }
`

const Text = styled.div`
  padding-top: 40px;
  max-width: 730px;
  p {
    color: ${props => props.theme.white};
  }
`

const Tag = styled.div`
  text-transform: uppercase;
  color: white;
  background-color: rgba(82, 145, 206, 0.8);
  padding: 8px 15px;
  border-radius: 8px;
  font-size: 14px;
  margin-bottom: 25px;
`

const WrapperSlider = styled.div`
  width: 100%;
  margin-top: 2.5rem;
  position: relative;
  display: block;
  height: 100%;
  .slick-arrow.slick-next {
    right: 0;
    transform: none;
    top: 25%;
  }
  .slick-arrow.slick-prev {
    left: 0;
    transform: none;
    top: 25%;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    margin-top: 1.5rem;
  }
`
const Item = styled.div`
  margin-bottom: 2rem;
  @media (min-width: ${globalVariables.minTablet}) {
    margin-bottom: 0;
  }
`

const Number = styled.p`
  font-size: 80px;
  font-weight: bold;
  color: ${props =>
    props.couleurStat === "Bleu"
      ? props.theme.primary
      : props.couleurStat === "Blanc"
      ? props.theme.white
      : props.theme.primary};
  margin-bottom: 0;
  @media (min-width: ${globalVariables.minTablet}) {
    font-size: 70px;
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    font-size: 90px;
  }
`
const Label = styled.p`
  font-size: 20px;
  text-transform: uppercase;
  color: white;
  margin-bottom: 0;
`

const Banner = ({
  title,
  description,
  sector,
  stat,
  couleurStat,
  imageBanner,
  imageHeight,
  imageMobileHeight,
  statut,
}) => {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: stat.length,
    slidesToScroll: 0,
    initialSlide: 0,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          customPaging: function(i) {
            return <Dots />
          },
          appendDots: dots => <WrapperDots>{dots}</WrapperDots>,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Section
      padding="0"
      imageHeight={statut !== "Caché" ? imageHeight : "410px"}
      imageMobileHeight={statut !== "Caché" ? imageMobileHeight : "485px"}
      isfirstBlockOnMobile
      zIndex="1"
    >
      <Img fluid={imageBanner} />
      <Wrapper>
        <Container>
          <Content>
            <Tag>{sector}</Tag>
            <Title>{title}</Title>
            <Text>{Parser(description)}</Text>
            {statut !== "Caché" && (
              <WrapperSlider>
                <Slider {...settings}>
                  {stat.map((item, index) => (
                    <Item key={index}>
                      <Flip left delay={index * 300}>
                        <Number couleurStat={couleurStat}>
                          {item.chiffres.chiffre}
                        </Number>
                        <Label>{item.chiffres.texte}</Label>
                      </Flip>
                    </Item>
                  ))}
                </Slider>
              </WrapperSlider>
            )}
          </Content>
        </Container>
      </Wrapper>
    </Section>
  )
}

export default Banner
