import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/EtudeDeCas/Banner"
import EtudeDeCasBlocs from "../components/EtudeDeCas"
import Contact from "../components/Home/Contact"
import Temoignage from "../components/Home/Temoignage"
import ReferencesList from "../components/References"

const EtudeDeCas = ({ data, location }) => {
  const { etude_de_cas, etudes_de_cas, bloc_contact } = data

  const getDataReferences = data => {
    const dataFiltered = data
      .filter(item => item.node.path !== etude_de_cas.path)
      .slice(0, 3)
    return dataFiltered
  }

  return (
    <Layout
      location="/nos-references"
      locationBis={location.pathname}
      crumbLabel="Nos Références"
      crumbLabelBis={etude_de_cas.acf.titre_banniere_etude_de_cas}
      locationGTM={location.href}
    >
      <SEO
        title={etude_de_cas.acf.title}
        description={etude_de_cas.acf.meta_description}
        lang="fr"
        url={location.pathname}
      />
      <Banner
        imageBanner={
          etude_de_cas.acf.image_etude_de_cas.localFile.childImageSharp.fluid
        }
        title={etude_de_cas.acf.titre_banniere_etude_de_cas}
        description={etude_de_cas.acf.texte_banniere_etude_de_cas}
        sector={etude_de_cas.acf.secteur_banniere_etude_de_cas}
        stat={etude_de_cas.acf.statistiques}
        couleurStat={etude_de_cas.acf.couleur_statistiques_etude_de_cas}
        imageHeight="550px"
        imageMobileHeight="760px"
        isCenter
        statut={etude_de_cas.acf.statut_chiffres}
      />
      <EtudeDeCasBlocs
        couleur={etude_de_cas.acf.couleur_vignette_etude_de_cas}
        logo={
          etude_de_cas.acf.logo_etude_de_cas.localFile.childImageSharp.fluid
        }
        vignette={
          etude_de_cas.acf.image_vignette_etude_de_cas.localFile.childImageSharp
            .fluid
        }
        client={etude_de_cas.acf.le_client}
        contexte={etude_de_cas.acf.section_contexte}
        missions={etude_de_cas.acf.section_missions}
        resultats={etude_de_cas.acf.section_resultats}
      />
      {etude_de_cas.acf.statut_temoignage !== "Caché" && (
        <Temoignage
          imageHeight="480px"
          padding="10px 0"
          noDots
          items={[
            {
              ...etude_de_cas.acf.temoignage,
              logo_temoignage: etude_de_cas.acf.logo_etude_de_cas,
            },
          ]}
        />
      )}
      <ReferencesList
        items={getDataReferences(etudes_de_cas.edges)}
        title="D'autres références"
        buttonText="Toutes les références"
        buttonUrl="/nos-references"
      />
      <Contact
        title={bloc_contact.acf.titre_contact}
        text={bloc_contact.acf.texte_contact}
        location={location.href}
      />
    </Layout>
  )
}

export default EtudeDeCas

export const query = graphql`
  query($id: String!) {
    etude_de_cas: wordpressWpEtudesDeCas(id: { eq: $id }) {
      path
      acf {
        title
        meta_description
        secteur_banniere_etude_de_cas
        titre_banniere_etude_de_cas
        texte_banniere_etude_de_cas
        couleur_vignette_etude_de_cas
        couleur_statistiques_etude_de_cas
        statut_temoignage
        statut_chiffres
        statistiques {
          chiffres {
            chiffre
            texte
          }
        }
        le_client {
          titre_section_client
          texte_section_client
        }
        section_contexte {
          titre_section_contexte
          texte_section_contexte
        }
        section_missions {
          titre_missions
          texte_missions
        }
        section_resultats {
          titre_resultats
          texte_resultats
        }
        image_etude_de_cas {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        logo_etude_de_cas {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        image_vignette_etude_de_cas {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        temoignage {
          nom_temoignage: nom
          fonction_temoignage: fonction
          texte_temoignage: texte
        }
      }
    }
    etudes_de_cas: allWordpressWpEtudesDeCas(limit: 4) {
      edges {
        node {
          path
          date
          acf {
            creation_etude_de_cas
            titre_banniere_etude_de_cas
            texte_banniere_etude_de_cas
            secteur_banniere_etude_de_cas
            prestations {
              texte_prestation
            }
            image_vignette_etude_de_cas {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            logo_etude_de_cas {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            couleur_vignette_etude_de_cas
          }
        }
      }
    }
    bloc_contact: wordpressAcfBlocs(
      id: { eq: "76393d63-8408-5238-b201-7f73d6fe7a78" }
    ) {
      acf {
        titre_contact
        texte_contact
      }
    }
  }
`
