import React from "react"
import styled from "styled-components"

import globalVariables from "../globalVariables"
import Title from "../elements/Title"
// import Container from "../elements/Container"

const Wrapper = styled.div`
  flex: ${props => (props.flex ? props.flex : "1 1 100%")};
  border-top: 1px solid white;
  background: ${props =>
    props.bgColor ? props.theme[props.bgColor] : "white"};
  padding: 40px 20px;

  p {
    color: ${props => (props.color ? props.theme[props.color] : null)};
  }

  @media (min-width: ${globalVariables.minTablet}) {
    padding: 55px 40px;
    border-right: ${props => (props.borderRight ? "1px solid white" : null)};

    .title1 {
      text-align: ${props => (props.right ? "right" : "left")};
    }
  }
  @media (max-width: ${globalVariables.maxMobile}) {
    order: ${props => (props.order ? props.order : null)};
  }
`

const HeadTitle = styled.p`
  position: relative;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 13px;

  &::after {
    content: "";
    position: absolute;
    bottom: -26px;
    left: -20px;
    height: 3px;
    width: 170px;
    background: ${props =>
      props.color ? props.theme[props.color] : props.theme.primary};
  }
  @media (min-width: ${globalVariables.minTablet}) {
    text-align: ${props => (props.right ? "right" : "left")};
    &::after {
      left: ${props => (props.right ? "auto" : "-40px")};
      right: ${props => (props.right ? "-40px" : "auto")};
    }
    &.title-container::after {
      width: 475px;
      left: -140px;
    }
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    &::after {
      width: 375px;
    }
    &.title-container::after {
      width: 575px;
      left: -190px;
    }
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    &.title-container::after {
      left: -290px;
    }
  }
`
const Text = styled.div`
  @media (min-width: ${globalVariables.minTablet}) {
    margin-right: ${props =>
      props.container ? "0" : !props.right ? "90px" : null};
    margin-left: ${props => (props.right ? "90px" : null)};
    p {
      text-align: ${props => (props.right ? "right" : "left")};
    }
  }
`

const Container = styled.div`
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 0 100px;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    padding: 0 150px;
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    padding: 0 250px;
  }
`

const Bloc = ({
  flex,
  bgColor,
  color,
  right,
  title,
  subTitle,
  text,
  container,
  order,
  borderRight,
}) => {
  return (
    <Wrapper
      flex={flex}
      bgColor={bgColor}
      color={color}
      right={right}
      order={order}
      borderRight={borderRight}
    >
      {container ? (
        <Container>
          <HeadTitle color={color} right={right} className="title-container">
            {title}
          </HeadTitle>
          <Title className="title1" color={color} margin="50px 0 20px 0">
            {subTitle}
          </Title>
          <Text right={right} container={container}>
            {text}
          </Text>
        </Container>
      ) : (
        <React.Fragment>
          <HeadTitle color={color} right={right}>
            {title}
          </HeadTitle>
          <Title className="title1" color={color} margin="50px 0 20px 0">
            {subTitle}
          </Title>
          <Text right={right}>{text}</Text>
        </React.Fragment>
      )}
    </Wrapper>
  )
}

export default Bloc
